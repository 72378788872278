import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getCurso } from "../actions/";
import CursoActual from "../components/CursoActual";

const Curso = props => {
  const { getCurso, history } = props;
  useEffect(() => {
    getCurso(history.location.pathname.substr(1));
    window.scrollTo(0, 0);
  }, [getCurso, history]);

  const { cursoActual } = props;

  if (cursoActual === null) {
    props.history.push("/404");
  }
  return (
    <>
      <CursoActual {...cursoActual} otro={props.history}></CursoActual>
    </>
  );
};

const mapStateToProps = state => {
  return {
    cursoActual: state.cursoActual
  };
};
const mapDispatchToProps = {
  getCurso
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Curso);
