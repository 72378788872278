import React from "react";
import { connect } from "react-redux";
import { guardarVistos } from "../actions";
import "../assets/styles/ListadoVideos.css";
import Chat from "./Chat";

const ListadoVideos = props => {
  const {
    programa,
    vistos,
    cursin,
    user,
    total_final,
    _color_,
    img_prod,
    nombre
  } = props;
  const closebtn = document.getElementById("closebtn");
  const openbtn = document.getElementById("openbtn");
  const navBar = document.getElementById("mySidenav");
  const listado = document.getElementById("listadoVideos");
  const frame = document.getElementById("videoX");
  const txp = document.getElementById("txp");
  const botonDescargas = document.getElementById("descargas");
  let botonera = false;

  let actual = "https://player.vimeo.com/video/269582575";
  let txtactual = "";
  let bb = "";
  const createPrograma = () => {
    let table = [];
    let id = 0;
    for (const prop in programa) {
      id++;
      let pdf = "nada";
      if (programa[prop].pdf != undefined) {
        pdf = programa[prop].pdf;
      }
      table.push(
        <li key={prop}>
          <a href="pp">
            <p>
              <span
                className="itemtxt"
                url={programa[prop].url}
                txt={programa[prop].txt}
                pdf={pdf}
                onClick={handleClick}
              >
                {programa[prop].nombre}{" "}
              </span>
            </p>
          </a>
        </li>
      );
    }
    return table;
  };
  const activo = () => {
    const frame = document.getElementById("videoX");
    let elms = document.getElementsByClassName("itemtxt");
    let elmsn = document.getElementsByClassName("numeroloco");
    for (var p = 0; p <= elms.length - 1; p++) {
      elms[p].classList.remove("videoActivo");
      let v = elms[p].getAttribute("url");
      if (v === frame.src) {
        elms[p].classList.add("videoActivo");
      }
    }
    for (p = 0; p <= elmsn.length - 1; p++) {
      elmsn[p].classList.remove("numeroActivo");
      let v = elmsn[p].getAttribute("url");
      if (v === frame.src) {
        elmsn[p].classList.add("numeroActivo");
      }
    }
  };
  const _vistos = () => {
    for (const prop in vistos) {
      addVisto(vistos[prop].url);
    }
  };
  const addVisto = item => {
    let elms = document.getElementsByClassName("itemtxt");
    let elmsn = document.getElementsByClassName("numeroloco");
    for (let p = 0; p <= elms.length - 1; p++) {
      let v = elms[p].getAttribute("url");

      if (v === item) {
        elms[p].classList.add("visto");
      }
    }
    for (let pp = 0; pp <= elmsn.length - 1; pp++) {
      let vv = elmsn[pp].getAttribute("url");

      if (vv === item) {
        elmsn[pp].classList.add("numeroVisto");
      }
    }
  };
  const handleClickExtra = e => {
    e.preventDefault();

    let url = e.target.attributes.url.value;
    let tipo = e.target.attributes.tipo.value;
    if (tipo === "link") {
      window.open(url, "_blank");
    }
    if (tipo === "video") {
      frame.setAttribute("src", url);
    }
  };
  const bajar = e => {
    e.preventDefault();
  };
  const handleClick = e => {
    e.preventDefault();
    if (!botonera) {
      botonera = true;
    }
    closeNav();
    actual = e.target.attributes.url.value;
    txtactual = e.target.attributes.txt.value;
    bb = e.target.attributes.pdf.value;
    if (bb == "nada") {
      botonDescargas.style.display = "none";
    } else {
      botonDescargas.setAttribute(
        "href",
        `http://escueladevrock.com/statics/${bb}`
      );
      botonDescargas.style.display = "block";
    }
    frame.setAttribute("src", actual);
    txp.innerHTML = txtactual;

    // const tit = document.getElementById("videoNombre");
    // tit.innerHTML = e.target.innerHTML;
    activo();
    _vistos();
  };
  const handleGuardarVistos = e => {
    e.preventDefault();
    const frame = document.getElementById("videoX");
    props.guardarVistos({
      cursin: cursin,
      video: frame.src,
      pibe: user.uid,
      progreso: _indicar_progreso()
    });
  };
  const _indicar_progreso = () => {
    const Lista = listado;
    let total = Lista.children.length - 1;
    var p = Lista.getElementsByClassName("visto").length - 1;
    var porcentaje = Math.round((p * 100) / total);
    return porcentaje;
  };
  const openNav = e => {
    _vistos();

    navBar.style.width = "300px";
    navBar.style.height = "80vh";
    document.getElementById("mainx").style.marginLeft = "250px";
    let info = document.getElementById("headerListadoInfo");
    info.style.display = "block";
    openbtn.style.display = "none";
    closebtn.style.display = "block";
    let elms = document.getElementsByClassName("itemtxt");
    let elmsn = document.getElementsByClassName("numeroloco");

    for (var x = 0; x <= elms.length - 1; x++) {
      elms[x].style.display = "block";
    }

    for (var c = 0; c <= elmsn.length - 1; c++) {
      elmsn[c].style.display = "none";
    }
  };
  const createExtras = e => {
    let table = [];
    let id = 0;
    for (const prop in programa.extras) {
      id++;

      table.push(
        <li key={id}>
          <a href="pepe">
            <p>
              <span
                className="numeroloco"
                url={programa.extras[prop].url}
                tipo={programa.extras[prop].tipo}
                onClick={handleClickExtra}
              >
                {id - 2}
              </span>
              <span
                className="itemtxt"
                url={programa.extras[prop].url}
                tipo={programa.extras[prop].tipo}
                onClick={handleClickExtra}
              >
                {programa.extras[prop].nombre}{" "}
              </span>
            </p>
          </a>
        </li>
      );
    }
    return table;
  };
  const closeNav = e => {
    // closeChat();

    navBar.style.height = "260px";
    navBar.style.width = "50px";
    // document.getElementById("chatWrapper").style.height = 0;
    document.getElementById("mainx").style.marginLeft = 0;
    let info = document.getElementById("headerListadoInfo");
    info.style.display = "none";
    openbtn.style.display = "block";
    closebtn.style.display = "none";
    let elms = document.getElementsByClassName("itemtxt");
    let elmsn = document.getElementsByClassName("numeroloco");

    for (var d = 0; d <= elms.length - 1; d++) {
      elms[d].style.display = "none";
    }

    for (var s = 0; s <= elmsn.length - 1; s++) {
      elmsn[s].style.display = "flex";
    }
  };
  const openvVideos = () => {
    var alto = document.getElementById("listadoVideos").style.height;
    console.log("VIDEOS LOCOS CLICK", alto);

    if (alto === 0 || alto === "0px" || alto === "") {
      openNav();
      document.getElementById("listadoVideos").style.height = "55vh";
      // document.getElementById("chatWrapper").style.height = 0;
      // document.getElementById("listadoExtras").style.height = 0;
      // closeChat();
    } else {
      document.getElementById("listadoVideos").style.height = 0;
      closeNav();
    }
  };
  const openExtras = () => {
    closeChat();
    var alto = document.getElementById("listadoExtras").style.height;
    if (alto === 0 || alto === "0px") {
      document.getElementById("chatWrapper").style.height = 0;
      document.getElementById("listadoExtras").style.height = "20vh";
      document.getElementById("listadoVideos").style.height = 0;
      openNav();
    } else {
      document.getElementById("listadoExtras").style.height = 0;
      closeNav();
    }
    openNav();
  };

  const _siguinte = e => {
    e.preventDefault();

    var Lista = listado;
    let elms = document.getElementsByClassName("itemtxt");
    for (var p = 0; p <= elms.length - 1; p++) {
      let v = elms[p].getAttribute("url");
      if (v === frame.src && p < Lista.children.length - 1) {
        actual = elms[p + 1].getAttribute("url");
        frame.setAttribute("src", actual);
        activo();
        _vistos();
        return;
      }
    }
  };
  const _anterior = e => {
    e.preventDefault();

    // eslint-disable-next-line
    var Lista = listado;
    let elms = document.getElementsByClassName("itemtxt");
    for (var p = 0; p <= elms.length - 1; p++) {
      let v = elms[p].getAttribute("url");
      if (v === frame.src && p > 0) {
        actual = elms[p - 1].getAttribute("url");
        frame.setAttribute("src", actual);
        activo();
        _vistos();
        return;
      }
    }
  };

  const closeChat = () => {
    document.getElementById("chatWrapper").style.height = 0;
    document.getElementById("chatWrapper").style.display = "none";
  };
  const openChat = () => {
    var alto = document.getElementById("chatWrapper").style.height;
    document.getElementById("chatWrapper").style.display = "block";
    document.getElementById("listadoExtras").style.height = 0;
    document.getElementById("listadoVideos").style.height = 0;
    if (alto === 0 || alto === "0px") {
      document.getElementById("chatWrapper").style.height = "46vh";
      openNav();
    } else {
      document.getElementById("chatWrapper").style.height = 0;
      document.getElementById("chatWrapper").style.display = "none";

      closeNav();
    }
  };

  return (
    <>
      <div id="mySidenav" className="sidenav">
        <header className="headerListado" style={{ backgroundColor: _color_ }}>
          <button
            onClick={openvVideos}
            className="openbtn"
            id="openbtn"
          ></button>
          <button
            onClick={closeNav}
            className="closebtn"
            id="closebtn"
          ></button>
          <div
            className="listadoVideosLogo"
            style={{ backgroundColor: _color_ }}
          >
            <img
              src={`https://escueladevrock.com/statics/${img_prod}`}
              alt=""
              className="listadoVideos__pic"
            />
          </div>
          <div className="headerListadoInfo" id="headerListadoInfo">
            <h2 className="headerListadoTitulo">{nombre}</h2>
            {/* <progress value={total_final} max="100" id="progreso"></progress> */}
            {/* <span className="headerListadocompleto">{total_final} %</span> */}
          </div>
        </header>

        <span onClick={openvVideos} className="sidebarBotones openVideo">
          <i className="far fa-play-circle"></i>
        </span>
        <ul id="listadoVideos">{createPrograma()}</ul>
        <hr />
        {/* <span href="#" onClick={openExtras} className="sidebarBotones">
          <i className="fas fa-paperclip"></i>
        </span>
        <ul id="listadoExtras">{createExtras()}</ul>
        <hr /> */}

        {/* <span href="#" onClick={openChat} className="sidebarBotones">
          <i className="far fa-comment-dots"></i>
        </span>
        <div className="chatWrapper" id="chatWrapper">
          <Chat cursin={cursin}></Chat>
        </div> */}
      </div>

      <div className="botonera" id="btnra"></div>
      <div className="mainx" id="mainx">
        <iframe
          title="myFrame"
          rows="1"
          id="videoX"
          width="500px"
          height="auto"
          src={actual}
          frameBorder="0"
          allowFullScreen
          className="embed-responsive-item"
          style={{ backgroundColor: "black" }}
        ></iframe>
      </div>
      <div className="container ppp">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h3>Sinopsis</h3>
            <p id="txp">Gracias por ser parte de nuestro curso de stand up.</p>
            <a
              href=""
              type="button"
              class="btn btn-warning"
              id="descargas"
              target="blank"
            >
              <i class="fas fa-download "> Bajar materiales</i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return { user: state.user, totales: state.totales };
};

const mapDispatchToProps = {
  guardarVistos
};
export default connect(mapStateToProps, mapDispatchToProps)(ListadoVideos);
