import React from "react";
import CursoTema from "./CursoTema";
const CursoTemas = props => {
  const { temas, _color_ } = props;
  const crearTemas = () => {
    let table = [];
    let id = "";
    for (const prop in temas) {
      id++;
      table.push(
        <CursoTema {...temas[prop]} _color_={_color_} key={id}></CursoTema>
      );
    }
    return table;
  };
  return (
    <>
      <hr />
      {crearTemas()}
      <hr />
    </>
  );
};
export default CursoTemas;
