import React, { useState } from "react";
import "../assets/styles/CursoActual.css";
import { connect } from "react-redux";
import CursoSobre from "./CursoSobre";
import CursoFAQ from "./CursoFAQ";
import SwipeableViews from "react-swipeable-views";
import CursoPrograma from "./CursoPrograma";
import { Helmet } from "react-helmet";
import { addCarrito } from "../actions";
import SobreMi from "./SobreMi";

const CursoActual = props => {
  const {
    nombre,
    logo,
    precio,

    _color_,
    longDescription,
    pregunta0,
    pregunta1,
    programa,
    url,
    oferta,

    descuento,
    otro
  } = props;
  const [CustomState, setCustomState] = useState({
    index: 0
  });
  const handleChangeIndex = e => {
    activos(e);
  };
  const handleSolapa = e => {
    e.preventDefault();
    setCustomState({ index: parseInt(e.target.name) });
    activos(e.target.name);
  };
  const handleComprar = e => {
    e.preventDefault();
    props.addCarrito(url.substr(1));
    otro.push("/comprar");
  };
  let precioFinal = 0;
  if (oferta !== "no") {
    let des = 100 - descuento;
    precioFinal = (precio * des) / 100;
  }
  const activos = e => {
    const elementosActivo = document.querySelector(".activo");
    elementosActivo.classList.remove("activo");
    elementosActivo.classList.add("pasivo");
    const elementos = document.querySelectorAll(".opciones");
    elementos[e].classList.add("activo");
  };
  return (
    <>
      <Helmet>
        <title>{`Escuela Dev Rock | Curso de ${nombre}`}</title>
        <meta name="description" content={longDescription} />
      </Helmet>
      <div className="container">
        <div className="cursoHeader" style={{ backgroundColor: _color_ }}>
          <div className="cursoHeader--item">
            <img
              src={`https://escueladevrock.com/${logo}`}
              alt=""
              className="cursoLogo"
            />
          </div>
          <div className="">
            <h1 className="cursoHeader--nombre">{nombre}</h1>
            {oferta === "no" ? (
              <h3 className="cursoHeader--precio">AR${precio}</h3>
            ) : (
              <>
                <p className="carousel-item__details--oferta">
                  <del>AR$ {precio}</del> {oferta}
                </p>
                <h3 className="cursoHeader--precio">AR${precioFinal}</h3>
              </>
            )}

            <button onClick={handleComprar}>COMPRAR</button>
          </div>
        </div>
        <div className="cursoTabs">
          <ul className="curso__nav">
            <li>
              <button
                onClick={handleSolapa}
                className="activo opciones"
                name="0"
              >
                Sobre el curso
              </button>
            </li>
            <li>
              <button
                onClick={handleSolapa}
                className="pasivo opciones"
                name="1"
              >
                FAQ
              </button>
            </li>
            <li>
              <button
                onClick={handleSolapa}
                className="pasivo opciones"
                name="2"
              >
                Programa
              </button>
            </li>
            <li>
              <button
                onClick={handleSolapa}
                className="pasivo opciones"
                name="3"
              >
                Sobre Mi
              </button>
            </li>
          </ul>
          <div className="cursoContenido">
            <SwipeableViews
              index={CustomState.index}
              onChangeIndex={handleChangeIndex}
            >
              <div className="curso-wrap">
                <p className="longDescription">{longDescription}</p>
                <hr />
                <CursoSobre _color_={_color_}></CursoSobre>
              </div>
              <div className="curso-wrap">
                <CursoFAQ {...pregunta0}></CursoFAQ>
                <br />
                <CursoFAQ {...pregunta1}></CursoFAQ>
              </div>
              <div className="curso-wrap">
                <h2>Programa completo</h2>
                <CursoPrograma
                  programa={programa}
                  _color_={_color_}
                ></CursoPrograma>
              </div>
              <div className="curso-wrap">
                <SobreMi _color_={_color_}></SobreMi>
              </div>
            </SwipeableViews>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  addCarrito
};

export default connect(
  null,
  mapDispatchToProps
)(CursoActual);
