import React, { useEffect } from "react";
import { connect } from "react-redux";
import { verCurso } from "../actions";
import ListadoVideos from "../components/ListadoVideos";

const Ver = props => {
  const {
    location,
    verCurso,
    verCursoInfo,
    user,
    totales,
    verCursoTxt
  } = props;

  useEffect(() => {
    let curso = location.pathname.substr(5);
    verCurso(curso);
  }, [location, verCurso]);

  const { nombre, _color_, img_prod } = verCursoTxt;
  const vistos = `vistos_${props.location.pathname.substr(5)}`;
  const calculaTotal = () => {
    if (totales) {
      return totales[props.location.pathname.substr(5)];
    } else {
      return 0;
    }
  };

  return (
    <>
      {/* <br />
      <br /> <br />
      <br />
      <h3>{nombre} </h3>
      <img
        src={`https://escueladevrock.com/statics/${img_prod}`}
        alt=""
        className="cursoComprado__pic"
      /> */}
      <ListadoVideos
        nombre={nombre}
        _color_={_color_}
        img_prod={img_prod}
        cursin={props.location.pathname.substr(5)}
        programa={verCursoInfo}
        vistos={user[vistos]}
        total_final={calculaTotal()}
      ></ListadoVideos>
    </>
  );
};
const mapStateToProps = state => {
  return {
    verCursoInfo: state.verCursoInfo,
    userInfo: state.userInfo,
    user: state.user,
    totales: state.totales,
    verCursoTxt: state.verCursoTxt
  };
};

const mapDispatchToProps = {
  verCurso
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ver);
