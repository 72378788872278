import React from "react";
import LoaderImg from "../assets/statics/loader.gif";

import "../assets/styles/Loader.css";
const Loader = props => {
  return (
    <>
      <div className="loader-wraper">
        <img
          src="https://escueladevrock.com/statics/logoSDH.png"
          alt="Logo Señales de humor"
          className="loader-img"
        />
      </div>
    </>
  );
};

export default Loader;
