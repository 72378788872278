import React from "react";
const CursoSobre = props => {
  const { _color_ } = props;
  return (
    <>
      <div className="gridSobre">
        <ul>
          <li>
            <p>
              <i className="fas fa-coffee fa-lg" style={{ color: _color_ }}></i>{" "}
              Empezalo y terminalo a tu tiempo
            </p>
          </li>
          <li>
            <p>
              &nbsp;&nbsp;
              <i
                className="fas fa-clipboard-list fa-lg"
                style={{ color: _color_ }}
              ></i>
              &nbsp;&nbsp;Curso completo
            </p>
          </li>
          <li>
            <p>
              &nbsp;&nbsp;
              <i
                className="fas fa-file-video fa-lg"
                style={{ color: _color_ }}
              ></i>
              &nbsp;&nbsp;+30 videos
            </p>
          </li>
          <li>
            <p>
              <i className="fas fa-clock fa-lg" style={{ color: _color_ }}></i>{" "}
              Ya disponible
            </p>
          </li>
        </ul>

        <ul>
          <li>
            <p>
              <i
                className="fas fa-sync-alt fa-lg"
                style={{ color: _color_ }}
              ></i>{" "}
              Miralo cuantas veces quieras
            </p>
          </li>
          <li>
            <p>
              &nbsp;
              <i
                className="fas fa-file-code fa-lg"
                style={{ color: _color_ }}
              ></i>{" "}
              Desarrollá proyectos prácticos
            </p>
          </li>
          <li>
            <p>
              <i
                className="fas fa-id-card fa-lg"
                style={{ color: _color_ }}
              ></i>{" "}
              Certificado digital
            </p>
          </li>
          <li>
            <p>
              <i className="fas fa-globe fa-lg" style={{ color: _color_ }}></i>{" "}
              Modalidad online
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CursoSobre;
