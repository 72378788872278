import React from "react";
import CarouselItem from "../components/CarouselItem";

import Carousel from "../components/Carousel";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../assets/styles/Home.css";
import { Helmet } from "react-helmet";
import Gabriel from "../assets/statics/gabriel.png";
import Gustavo from "../assets/statics/gustavo.png";
import German from "../assets/statics/german.png";
import Logo from "../assets/statics/logo.svg";
const Home = (props) => {
  const { fjs } = props;
  const history = props.history;

  return (
    <>
      <Helmet>
        <title>Curso de stand up online | Señales de humor</title>
        <meta
          name="description"
          content="Aprende las herramientas para escribir e interpretar monólogos de humor stand up. Sumate a nuestro curso gratuito."
        />
      </Helmet>
      <header className="header_">
        <div className="container">
          <div className="row floatLeft">
            <div className="col-md-6 video">
              <iframe
                src="https://player.vimeo.com/video/269582575"
                width="100%"
                height="360"
                frameBorder="0"
                allowFullScreen
                className="off"
                title="inicial
                "
              ></iframe>
            </div>
            <div className="col-md-6">
              <div className="venta">
                <h1 className="tituloloco">Suscribite al mini curso</h1>
                <h1 className="sdh">Introducción al stand up</h1>
                <h2>GRATIS</h2>

                <Link to="/login" className="btn btn-warning">
                  Sumate
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="pan">
        <div className="container flexi">
          <div className="col-md-11">
            <p className="copy">
              ¿Estás en tu casa haciendo la cuarentena por el coronavirus <br />{" "}
              y ya no sabés qué hacer? <br />
              ¿Tus amigos te dicen que tenés que hacer stand up?
              <br />
              ¿Se te ocurren cosas divertidas y no sabes cómo aprovecharlas?
              <br />
              ¿Te gusta el humor, la comedia, las sitcoms?
              <br />
              ¿Tenés curiosidad por subirte a un escenario a hacer reír?
              <br />
              ¿Queres escribir tus propios monólogos de humor y no sabes cómo
              empezar?
              <br />
              ¿Ves a un comediante y pensás yo también podría hacer eso?
              <br />
              <strong>Entonces… este curso es para vos.</strong>
            </p>
          </div>
          <div className="col-md-1 off">
            <i className="far fa-hand-paper fa-10x"></i>
          </div>
        </div>
      </div>
      <div className="tira"></div>

      <div className="pan blanco">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="centrado">curso de standup online</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 top50">
              <p>
                <span className="resaltador">A hacer humor se aprende</span>
                El humor es una habilidad que se puede adquirir. Hay
                estructuras, fórmulas, secretos y conocer estas herramientas
                puede cambiar tu forma de ver y hacer humor.
              </p>
              <p className="top50">
                Con el curso online de Señales de humor vas a aprender todo lo
                que hace falta para empezar en el camino de la comedia. Como
                escribir tus propios chistes, interpretarlos en el escenario,
                interactuar con el público, y resolver cualquier imprevisto en
                escena.
              </p>
              <p className="top50">
                Además te va a servir para incorporar humor en tu trabajo,
                cuando tengas que escribir textos o dar presentaciones ante
                clientes o compañeros. Este curso te va a ayudar a hacer tus
                presentaciones de forma más entretenida y con menos ansiedad.
              </p>
              <p className="top50">
                <span className="resaltador">Modalidad</span>
                Todo esto con las ventajas de un curso online: podés verlo
                tranquilo en tu casa, manejando tus tiempos, poder ver cada
                clase las veces que quieras, hasta incorporar los conceptos.
                Junto con la teoría, te vamos a ir proponiendo diferentes
                ejercicios que te van a ir llevando a escribir tu propio
                monólogo.
              </p>
              <p className="top50">
                Y para que todo quede bien claro, vamos a mostrarte muchos
                ejemplos donde vas a ver en acción a diferentes comediantes, o
                sea, vas a aprender mientras ves chistes y te reís.
              </p>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 top50">
              {fjs.length > 0 && (
                <>
                  <div className="main">
                    <h2 className="main__title">Cursos 100% Online</h2>
                  </div>
                  <Carousel>
                    {fjs.map((item, i) => (
                      <CarouselItem key={i} {...item} />
                    ))}
                  </Carousel>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="tira"></div>
      <div className="pan blanco">
        <div className="container">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4 centrado">
              <img src={Logo} alt="" className="logo-grande" />{" "}
            </div>
            <div className="col-md-4"></div>
          </div>
          <div className="row top50">
            <div className="col-md-2"></div>
            <div className="col-md-8 centrado">
              <h2>vos te preguntarás…</h2>{" "}
              <h1>
                <i className="fas fa-quote-left"></i>
                ¿quien da este curso?<i className="fas fa-quote-right"></i>
              </h1>
              <p className="top50">
                Te vamos a contar sobre nosotros, somos{" "}
                <span className="resaltador">Señales de Humor</span>, un grupo
                de stand up formado en el año 2006. Sus integrantes somos
                Gabriel Gómez, Gustavo Valiente y Germán Ven.{" "}
              </p>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row top50 personas">
            <div className="col-md-4">
              <img src={Gabriel} alt="" />
            </div>
            <div className="col-md-4">
              <img src={Gustavo} alt="" />{" "}
            </div>
            <div className="col-md-4">
              <img src={German} alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <p className="top50">
                En estos años hicimos{" "}
                <span className="resaltador">más de 2000 shows en vivo</span>, y
                en giras recorrimos más de 70 ciudades de la Argentina y del
                exterior. Estuvimos en todos los ciclos y programas relacionados
                con el stand up en Argentina como{" "}
                <span className="resaltador">
                  Bendita, Hora de Reír, Comedy Central, los festivales
                  Acercarte y Ciudad Emergente
                </span>
                . También estuvimos en el programa Los Comediantes en Montevideo
                y en el Festival internacional del humor en Colombia.
              </p>

              <p className="top50">
                En paralelo a nuestra experiencia como comediantes, hace más de
                10 años tenemos nuestra{" "}
                <span className="resaltador">
                  escuela de stand up en Buenos Aires
                </span>
                , donde damos cursos presenciales, por los que pasaron más de
                mil alumnos, muchos de los cuales se dedican profesionalmente a
                la comedia.
              </p>

              <p className="top50">
                Entre nuestros alumnos hay muchos perfiles diferentes, desde
                gente que quiere dedicarse a la comedia, hasta gente que tenía
                pendiente hacer alguna actividad artística, magos que quieren
                agregar humor a sus rutinas, docentes que quieren hacer más
                entretenidas sus clases, hasta algunos que solo buscan superar
                la timidez a la hora de hablar en público.
              </p>

              <p className="top50">
                <span className="resaltador">
                  Toda nuestra experiencia como comediantes profesionales y como
                  docentes de más de mil alumnos está sintetizada en este curso
                  online.
                </span>
              </p>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="centrado">Detalles del curso</h1>
              <h3 className="centrado">Disponible ya mismo!</h3>
            </div>
          </div>
          <div className="row top50">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="col-md-6">
                <ul>
                  <li>
                    <p>
                      <i className="fas fa-coffee fa-lg"></i> Empezá y terminá a
                      tu tiempo
                    </p>
                  </li>
                  <li>
                    <p>
                      &nbsp;&nbsp;
                      <i className="fas fa-clipboard-list fa-lg"></i>
                      &nbsp;&nbsp;Curso Completo
                    </p>
                  </li>
                  <li>
                    <p>
                      &nbsp;&nbsp;<i className="fas fa-file-video fa-lg"></i>
                      &nbsp;&nbsp;32 clases
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fas fa-clock fa-lg"></i> 4hs+ de videoclases
                      online
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>
                    <p>
                      <i className="fas fa-sync-alt fa-lg"></i> Miralo cuantas
                      veces quieras
                    </p>
                  </li>
                  <li>
                    <p>
                      &nbsp;<i className="fas fa-pencil-alt fa-lg"></i> Escribí
                      tu material
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fas fa-globe fa-lg"></i> Modalidad: Online
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
        <div className="container zero">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 top50">
              {fjs.length > 0 && (
                <>
                  <Carousel>
                    {fjs.map((item, i) => (
                      <CarouselItem key={i} {...item} />
                    ))}
                  </Carousel>
                </>
              )}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    fjs: state.fjs,
    isMobile: state.isMobile,
  };
};

export default connect(mapStateToProps, null)(Home);
