import React from "react";
const CursoFAQ = props => {
  const { pregunta, respuesta } = props;
  return (
    <>
      <h2> {pregunta}</h2>

      <hr />
      <p> {respuesta}</p>
    </>
  );
};

export default CursoFAQ;
