import React from "react";
import "../assets/styles/MercadoPago.css";
import LogoMercadoPago from "../assets/statics/mercadopago.svg";
import Tarjetas from "../assets/statics/tarjetas.png";
const MercadoPago = props => {
  const { precio, uid, email, carrito, username } = props;
  const imgProducto = () => {
    if (Object.keys(carrito).length) {
      const cantidad = Object.keys(carrito).length;
      console.log("cantidad de cursos", cantidad);
      if (cantidad > 1) {
        for (const prop in carrito) {
          if (carrito[prop].cierre) {
            console.log(carrito[prop].img_prod);
            return carrito[prop].img_prod;
          }
        }
      } else {
        console.log(carrito[0].img_prod);
        return carrito[0].img_prod;
      }
    }
  };

  const misCursos = () => {
    let cursosOk = "";
    let primero = false;
    for (const prop in carrito) {
      if (!carrito[prop].cierre) {
        if (!primero) {
          cursosOk = carrito[prop].url.substr(1);
          primero = true;
        } else {
          cursosOk = cursosOk + "-" + carrito[prop].url.substr(1);
        }
      }
    }
    return cursosOk;
  };
  console.log(precio, "el precio que tengo en MP component", imgProducto());
  return (
    <form
      className="formularioPAgo"
      name="formulario"
      method="post"
      action="https://cursodestanduponline.com/pagos/index.php"
    >
      <input name="idProducto" value={misCursos()} type="hidden" />
      <input name="producto" value={misCursos()} type="hidden" />
      <input name="precio" value={parseInt(precio)} type="hidden" />
      <input name="imagen" value={imgProducto()} type="hidden" />
      <input name="nombre" value={username} type="hidden" />
      <input name="uid" value={uid} type="hidden" />
      <input name="mail" value={email} type="hidden" />

      <button type="submit" value="COMPRAR" className="btn btn-success">
        <img
          src={LogoMercadoPago}
          alt="Mercado Pago"
          className="mercadopagobtn"
        />
      </button>
      <img src={Tarjetas} alt="tarjetas" className="tarjetas" />
    </form>
  );
};
export default MercadoPago;
