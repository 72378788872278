import React from "react";
import { connect } from "react-redux";
import CursoComprado from "../components/CursoComprado";
const MisCursos = (props) => {
  const { user, cursos } = props;
  const { username, uid, email } = user;
  console.log(user, "LA UID que tengo al momento");
  return (
    <>
      {!uid && props.history.push("/login")}
      <div className="container">
        {Object.keys(cursos).length > 0 ? (
          <>
            <h2 className="tituloMisCursos">
              Hey {username} estos son tus cursos!
            </h2>
          </>
        ) : (
          <>
            <h2 className="tituloMisCursos sin cursos">
              Hey {username} aun no tienes cursos. Puedes sumarte a nuestro
              curso gratis o comprar alguno :){" "}
            </h2>
          </>
        )}
        <div className="wrapper-cursosComprados">
          {cursos.map((item, i) => {
            if (item.modal === "co") {
              return (
                <CursoComprado
                  key={i}
                  {...item}
                  uid={uid}
                  userNombre={username}
                  userMail={email}
                  history={props.history}
                ></CursoComprado>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    cursos: state.misCursos,
    user: state.user,
  };
};

const mapDispatchToProps = {
  // setUser
};
export default connect(mapStateToProps, mapDispatchToProps)(MisCursos);
