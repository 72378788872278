import React from "react";
import ReactDOM from "react-dom";
import App from "./routes/App";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";

import reducers from "./reducers/";
import thunk from "redux-thunk";

const initialState = {
  isLoader: true,
  fjs: [],
  essentials: [],
  free: [],
  podcasts: [],
  cursos: [],
  cursoActual: [],
  user: [],
  carrito: [],
  autocarga: [],
  pack: false,
  numeroItems: 0,
  toast: [],
  hero: {},
  misCursos: [],
  totales: [],
  verCursoInfo: [],
  verCursoTxt: {},
  comentarios: [],
  ofertas: []
};

const store = createStore(reducers, initialState, applyMiddleware(thunk));
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
