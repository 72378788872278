import React, { useEffect } from "react";
import { connect } from "react-redux";
import { addComentarioGeneral, getComentarios, timeSince } from "../actions/";
import MensajeHijo from "./MensajeHijo";
import "../assets/styles/Chat.css";
const Chat = props => {
  const { getComentarios, comentarios, user, cursin } = props;
  useEffect(() => {
    getComentarios({ cursin: cursin });
  }, [getComentarios, cursin]);

  let key = "";
  const handleChange = e => {
    var el = e.target;
    setTimeout(function() {
      el.style.cssText = "height:auto; padding:0";

      el.style.cssText = "height:" + el.scrollHeight + "px";
    }, 0);
  };
  const RT = e => {
    document.getElementById("cajaRT").style.height = "22px";
    document.getElementById("cajaRT").style.padding = "5px";
    let nic = e.target.getAttribute("nic");
    key = e.target.getAttribute("keys");
    document.getElementById("RTnombre").innerHTML = nic;
  };
  const closeRT = e => {
    document.getElementById("cajaRT").style.height = "0px";
    document.getElementById("cajaRT").style.padding = "0px";
    key = "";
  };
  const handelPublicar = () => {
    let msn = document.getElementById("myTextarea").value;
    document.getElementById("myTextarea").value = "";
    let dato = {
      cursin: cursin,
      nombre: user.username,
      email: user.email,
      uid: user.uid,
      pic: user.profile_picture,
      mensaje: msn,
      key: key
    };
    props.addComentarioGeneral(dato);
  };

  const tirameLasAgujas = date => {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
      return interval + " años";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + "meses";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + "dias";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + "hs";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + "min";
    }
    return Math.floor(seconds) + "seg";
  };
  const handleEmoji = e => {
    const txt = document.getElementById("myTextarea");
    const emoji = e.target.innerHTML;
    txt.value = txt.value + emoji;
  };
  return (
    <>
      <div className="wraperMensajes">
        <ul>
          {comentarios.length > 0 &&
            comentarios.map((item, i) => (
              <li key={item.keys}>
                <div className="liWrapper">
                  <img src={item.pic} alt="" className="picChat" />
                  <div className="containerTXTChat">
                    <p className="mensajetxt">
                      <span className="nic">{item.nombre}</span> {item.mensaje}
                    </p>
                    <p className="mensajeDatos">
                      {tirameLasAgujas(item.time)}
                      <span
                        className="mensajeResponder"
                        keys={item.keys}
                        nic={item.nombre}
                        onClick={RT}
                      >
                        responder
                      </span>
                    </p>
                  </div>
                </div>
                <MensajeHijo msn={item.hilo} keys={item.keys}></MensajeHijo>
              </li>
            ))}
        </ul>
      </div>
      <div className="cajaComentarios">
        <div className="cajaRT" id="cajaRT">
          <p className="txtRT">
            Respondiendo a <span id="RTnombre"></span>
          </p>{" "}
          <span className="closeRT" onClick={closeRT}>
            <i className="fas fa-window-close"></i>
          </span>
        </div>
        <div className="cajaComentariosEmojis">
          <ul>
            <li>
              <span role="img" aria-label="Carita feliz" onClick={handleEmoji}>
                🙂
              </span>
            </li>
            <li>
              <span
                role="img"
                aria-label="Carita de Angel"
                onClick={handleEmoji}
              >
                😇
              </span>
            </li>
            <li>
              <span role="img" aria-label="caca" onClick={handleEmoji}>
                💩
              </span>
            </li>
            <li>
              <span role="img" aria-label="robot" onClick={handleEmoji}>
                🤖
              </span>
            </li>
            <li>
              <span
                role="img"
                aria-label="Carita asombrada"
                onClick={handleEmoji}
              >
                😱
              </span>
            </li>
            <li>
              <span
                role="img"
                aria-label="Carita invertida"
                onClick={handleEmoji}
              >
                🙃
              </span>
            </li>
            <li>
              <span
                role="img"
                aria-label="Carita de amor"
                onClick={handleEmoji}
              >
                😍
              </span>
            </li>
            <li>
              <span
                role="img"
                aria-label="Carita con lentes de sol"
                onClick={handleEmoji}
              >
                😎
              </span>
            </li>
          </ul>
          <hr />
        </div>
        <div className="cajaComentariosPublicar">
          <textarea
            className="inputText"
            placeholder="Piblicar como.."
            onChange={handleChange}
            id="myTextarea"
          />
          <span className="publicarBtn" onClick={handelPublicar}>
            Publicar
          </span>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return { user: state.user, comentarios: state.comentarios };
};

const mapDispatchToProps = {
  addComentarioGeneral,
  getComentarios,
  timeSince
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Chat);
