import React from "react";
import "../assets/styles/CarritoItems.css";
import { deleteItemCarrito, deletePackCarrito, sendToast } from "../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const CarritoItems = props => {
  const {
    nombre,
    img_prod,
    precio,
    _color_,
    oferta,
    descuento,
    pack,
    url,
    cierre,
    history
  } = props;
  const handleDelete = e => {
    e.preventDefault();

    props.sendToast([`Eliminaste el pack:"${nombre}" del carrito.`, "warn"]);
    props.deleteItemCarrito(nombre);
  };

  const handleDeletePack = e => {
    e.preventDefault();
    props.sendToast([`Eliminaste ${oferta} del carrito.`, "warn"]);
    history.location.hash = "";
    props.deletePackCarrito(oferta);
  };
  let precioFinal = 0;
  if (oferta !== "no") {
    let des = 100 - descuento;
    precioFinal = (precio * des) / 100;
  }
  return (
    <>
      {!cierre ? (
        <div className="itemCarrito" style={{ backgroundColor: _color_ }}>
          <div className="itemCarrito-foti-del">
            {!pack && (
              <button className="itemCarrito--delete" onClick={handleDelete}>
                <i className="fas fa-times"></i>
              </button>
            )}
            <img
              className="itemCarrito--img"
              src={`http://escueladevrock.com/statics/${img_prod}`}
              alt={nombre}
            />
          </div>
          <Link to={url} className="itemCarrito--titulo">
            <p className="itemCarrito--titulo">{nombre}</p>
          </Link>
          <div className="itemCarrito--soportePrecio">
            {precioFinal === 0 ? (
              <p className="itemCarrito--precio">AR${precio}</p>
            ) : (
              <>
                <del className="itemCarrito--precio-del">AR${precio}</del>
                <p className="itemCarrito--oferta">{oferta}</p>

                <p className="itemCarrito--precio">AR${precioFinal}</p>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <button
            className="itemCarrito--delete__pack"
            onClick={handleDeletePack}
          >
            <i className="fas fa-times"></i>{" "}
            <span className="itemCarrito--delete__leyenda">
              {" "}
              Eliminar del carrito el pack {nombre}
            </span>
          </button>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  deleteItemCarrito,
  deletePackCarrito,
  sendToast
};

export default connect(
  null,
  mapDispatchToProps
)(CarritoItems);
