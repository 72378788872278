import React from "react";

const Carousel = ({ children }) => (
  <React.Fragment>
    <section className="carousel">
      <div className="row centrado">{children}</div>
    </section>
  </React.Fragment>
);

export default Carousel;
