import React from "react";
const CursoTema = props => {
  const { nombre, tiempo, _color_ } = props;
  return (
    <>
      <div className="container-tema">
        <p className="tema">{nombre}</p>
        <p className="tema">
          <i className="fas fa-stopwatch fa-sm" style={{ color: _color_ }}></i>{" "}
          {tiempo}
        </p>
      </div>
    </>
  );
};
export default CursoTema;
