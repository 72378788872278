const reducer = (state, action) => {
  switch (action.type) {
    case "GET_FULLSTACK_CURSES_OK":
      return {
        ...state,
        fjs: [...state.fjs, action.payload]
      };
    case "GET_ESSENTIALS_CURSES_OK":
      return {
        ...state,
        essentials: [...state.essentials, action.payload]
      };
    case "GET_FREE_CURSES_OK":
      return {
        ...state,
        free: [...state.free, action.payload]
      };

    case "SEND_TOAST":
      return {
        ...state,
        toast: action.payload
      };

    case "GET_PODCASTS_OK":
      return {
        ...state,
        podcasts: action.payload
      };

    case "CURSO_ACTUAL":
      return {
        ...state,
        cursoActual: action.payload
      };
    case "DELETE_ITEM_CARRITO":
      return {
        ...state,
        carrito: state.carrito.filter(items => items.nombre !== action.payload)
      };

    case "GET_OFERTAS":
      return {
        ...state,
        ofertas: [...state.ofertas, action.payload]
      };

    case "DELETE_PACK_CARRITO":
      return {
        ...state,
        carrito: state.carrito.filter(items => items.oferta !== action.payload),
        pack: false
      };
    case "ADD_CARRITO_OK":
      let equal = state.carrito.filter(
        items => items.nombre === action.payload.nombre
      );
      if (equal.length === 0) {
        if (!action.payload.cierre) {
          state.numeroItems = state.numeroItems + 1;
        }
        return {
          ...state,
          carrito: [...state.carrito, action.payload]
        };
      }
      break;
    case "GET_COMENTARIOS_OK":
      return {
        ...state,
        comentarios: [...state.comentarios, action.payload]
      };

    case "ADD_MIS_CURSOS":
      let filtrado = state.cursos.filter(items => items.url === action.payload);
      let igualitos = state.misCursos.filter(
        items => items.url === action.payload
      );
      if (Object.keys(igualitos).length > 0) {
        filtrado = [];
      }
      return {
        ...state,
        misCursos: [...state.misCursos, ...filtrado]
      };
    case "VER_CURSO_TXT":
      return {
        ...state,
        verCursoTxt: action.payload
      };
    case "GET_HERO":
      return {
        ...state,
        hero: action.payload
      };

    case "VER_CURSO_INFO":
      return {
        ...state,
        verCursoInfo: action.payload
      };
    case "SAVE_TOTALES":
      return {
        ...state,
        totales: action.payload
      };
    case "IS_MOBILE":
      return {
        ...state,
        isMobile: action.payload
      };
    case "IS_LOADER":
      return {
        ...state,
        isLoader: action.payload
      };
    case "GET_USER":
      return {
        ...state,
        user: action.payload
      };
    case "BORRAR_STATE":
      return {
        ...state,
        cursoActual: [],
        user: [],
        cursos: [],
        carrito: [],
        autocarga: [],
        numeroItems: 0,
        toast: [],
        misCursos: [],
        totales: [],
        verCursoInfo: [],
        verCursoTxt: {},
        comentarios: []
      };

    case "GET_CURSOS_OK":
      return {
        ...state,
        cursos: [...state.cursos, action.payload]
      };

    default:
      return state;
  }
};

export default reducer;
