import firebase from "firebase/app";
import "firebase/database";
import xml2js from "xml2js";
import "firebase/auth";
import jsPDF from "jspdf";

const firebaseConfig = {
  apiKey: "AIzaSyD6yrxCL_G0wxbyuyd7zINaQbmQIa2xP6Q",
  authDomain: "sdhapp-8af64.firebaseapp.com",
  databaseURL: "https://sdhapp-8af64.firebaseio.com",
  projectId: "sdhapp-8af64",
  storageBucket: "sdhapp-8af64.appspot.com",
  messagingSenderId: "548864002473",
  appId: "1:548864002473:web:062ae7205418e309f59cb8",

  // DEVROCK Producion
  // apiKey: "AIzaSyAPYQ0W-w8jrUqANwCSWXZo2SaYgmwJkkA",
  // authDomain: "edrfinal.firebaseapp.com",
  // databaseURL: "https://edrfinal.firebaseio.com",
  // projectId: "edrfinal",
  // storageBucket: "edrfinal.appspot.com",
  // messagingSenderId: "771450384668",
  // appId: "1:771450384668:web:31c8ebeb5661fb04f14ad9"
  // DEVROCK testin
  // apiKey: "AIzaSyAXdg5fB-eavFKUEZnoZiHFkQNb4nB5W3s",
  // authDomain: "devrocklab.firebaseapp.com",
  // databaseURL: "https://devrocklab.firebaseio.com",
  // projectId: "devrocklab",
  // storageBucket: "devrocklab.appspot.com",
  // messagingSenderId: "200973673995",
  // appId: "1:200973673995:web:3aba21540969ebd332c90e"
};
firebase.initializeApp(firebaseConfig);
const db = firebase.database();
const dbRef = db.ref("cursosInfo");
const dbRefUsuario = db.ref("usuarios");

export const verCursoTxtOk = (payload) => ({
  type: "VER_CURSO_TXT",
  payload,
});

export const verCursoInfo = (payload) => ({
  type: "VER_CURSO_INFO",
  payload,
});

export const addMisCursos = (payload) => ({
  type: "ADD_MIS_CURSOS",
  payload,
});

export const borrarState = (payload) => ({
  type: "BORRAR_STATE",
  payload,
});

export const saveTotales = (payload) => ({
  type: "SAVE_TOTALES",
  payload,
});

export const isMobile = (payload) => ({
  type: "IS_MOBILE",
  payload,
});
export const getOfertas = (payload) => ({
  type: "GET_OFERTAS",
  payload,
});

export const deleteItemCarrito = (payload) => ({
  type: "DELETE_ITEM_CARRITO",
  payload,
});

export const deletePackCarrito = (payload) => ({
  type: "DELETE_PACK_CARRITO",
  payload,
});

export const getUser = (payload) => ({
  type: "GET_USER",
  payload,
});

export const isLoader = (payload) => ({
  type: "IS_LOADER",
  payload,
});

export const cursoActual = (payload) => ({
  type: "CURSO_ACTUAL",
  payload,
});

export const getCoursosOk = (payload) => ({
  type: "GET_CURSOS_OK",
  payload,
});

export const getFullStackJSCoursesOk = (payload) => ({
  type: "GET_FULLSTACK_CURSES_OK",
  payload,
});

export const getEssentialsCoursesOk = (payload) => ({
  type: "GET_ESSENTIALS_CURSES_OK",
  payload,
});

export const getFreeCoursesOk = (payload) => ({
  type: "GET_FREE_CURSES_OK",
  payload,
});

export const getPodcastsOk = (payload) => ({
  type: "GET_PODCASTS_OK",
  payload,
});

export const sendToast = (payload) => ({
  type: "SEND_TOAST",
  payload,
});

export const addCarritoOk = (payload) => ({
  type: "ADD_CARRITO_OK",
  payload,
});

export const getHeroOk = (payload) => ({
  type: "GET_HERO",
  payload,
});

export const getComentariosOk = (payload) => ({
  type: "GET_COMENTARIOS_OK",
  payload,
});

export const getInfo = () => async (dispatch) => {
  dispatch(dameEmails());

  dispatch(isMobileOUKE(false));
  dispatch(isLoader(true));
  dispatch(userExist());
  dbRef.on("child_added", (snapshot) => {
    const item = snapshot.val();
    dispatch(getCoursosOk(item));
    if (snapshot.val().grupo === "fjs") {
      const item = snapshot.val();
      dispatch(getFullStackJSCoursesOk(item));
    }
    dispatch(isLoader(false));
  });
};

export const getPodcasts = () => async (dispatch) => {
  const response = await fetch("https://pinecast.com/feed/devrock-podcast");
  const xmlString = await response.text();
  var parser = new xml2js.Parser(/* options */);
  parser
    .parseStringPromise(xmlString)
    .then(function (result) {
      const pod = result.rss.channel[0].item;
      dispatch(getPodcastsOk(pod));
    })
    .catch(function (err) {
      // Failed
    });
};

export const getCurso = (dato) => async (dispatch) => {
  dispatch(isLoader(true));
  dbRef
    .child(dato)
    .once("value")
    .then(function (snapshot) {
      const item = snapshot.val();
      dispatch(cursoActual(item));
      dispatch(isLoader(false));
    });
};

export const isMobileOUKE = (dato) => async (dispatch) => {
  const width = window.innerWidth;
  const payload = width <= 650;

  dispatch(isMobile(payload));
};

export const setUser = (dato) => async (dispatch) => {
  let provider = "";
  if (dato === "facebook") provider = new firebase.auth.FacebookAuthProvider();
  if (dato === "google") provider = new firebase.auth.GoogleAuthProvider();
  if (dato === "twitter") provider = new firebase.auth.TwitterAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      dispatch(getUserData(result.user.uid));
      dispatch(
        getUser({
          uid: result.user.uid,
          displayName: result.user.displayName,
          photoURL: result.user.photoURL,
          email: result.user.email,
          uid: result.user.uid,
          standupmini: true,
        })
      );
    })
    .catch((error) => console.log(`Error ${error.code}: ${error.message}`));
};

export const logout = (dato) => async (dispatch) => {
  firebase
    .auth()
    .signOut()
    .then((result) => {
      dispatch(borrarState());
      dispatch(getUser([]));
    })
    .catch((error) => console.log(`Error ${error.code}: ${error.message}`));
};
export const userExist = (dato) => async (dispatch) => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      dispatch(getUserData(user.uid));
    } else dispatch(getUser([]));
  });
};

export const addCarrito = (dato, descuento) => async (dispatch) => {
  dbRef
    .child(dato)
    .once("value")
    .then(function (snapshot) {
      let item = snapshot.val();
      if (descuento) {
        var info = descuento.split("-");
        item.oferta = info[0];
        item.descuento = info[1];
        item.pack = true;
      }
      if (dato === "zzzz") {
        item.cierre = true;
        item.nombre = info[0];
        item.img_prod = info[2];
      }
      dispatch(addCarritoOk(item));
    });
};

export const autoCarga = (dato) => async (dispatch) => {
  let Ofertas = dato.split(",");
  for (const prop in Ofertas) {
    if (prop !== "0") {
      const x = Ofertas[prop];
      dispatch(addCarrito(x, Ofertas[0]));
    }
  }
};

export const getHero = () => async (dispatch) => {
  dbRef.child("hero").on("child_added", (snapshot) => {
    const item = snapshot.val();
    if (item.state) {
      dispatch(getHeroOk(item));
    } else {
      dispatch(getOfertas(item));
    }
  });
};

export const getUserData = (dato) => async (dispatch) => {
  setTimeout(function () {
    dbRefUsuario.child(dato).on("value", (snapshot) => {
      const item = snapshot.val();
      if (item) {
        dispatch(getUser(item));
        if (item.totales) {
          dispatch(saveTotales(item.totales));
        }

        // eslint-disable-next-line
        let result = Object.keys(item).map(function (key) {
          if (item[key] === true) {
            const name = "/" + key;
            dispatch(addMisCursos(name));
          }
        });
      }
    });
  }, 1030);
};

export const bancameUnCacho = (dato) => async (dispatch) => {
  setTimeout(function () {
    dispatch(getUserData(dato));
  }, 500);
};

export const bajarCertificado = (dato) => async (dispatch) => {
  const { curso, userNombre, mail, qr, uidx } = dato;
  const dbRef = db.ref("certificados").child(curso);

  dbRef.on("value", (snap) => {
    const cer = snap.val();
    var doc = new jsPDF("landscape", "mm");
    doc.addImage(cer, "PNG", 0, 0, 297, 210);
    doc.addImage(qr, "PNG", 20, 130, 50, 50);
    doc.setFontSize(40);
    doc.setFont("helvetica");
    doc.setFontType("light");
    doc.setTextColor(79, 79, 79);
    doc.text(25, 80, userNombre);
    doc.setFontSize(15);
    doc.text(25, 88, mail);
    doc.setFontType("bold");
    doc.text(25, 193, _hoyFecha());
    doc.setFontSize(10);
    doc.text(25, 204, uidx);
    const nombre = "DevRockCertifica-" + curso + ".pdf";
    doc.save(nombre);
  });
};
export const verCurso = (dato) => async (dispatch) => {
  const dbRef = db.ref("cursos").child(dato);
  dispatch(isLoader(true));

  dbRef.on("value", (snap) => {
    const curso = snap.val();
    dispatch(verCursoInfo(curso));
    dispatch(verCursoTxt(`/${dato}`));
    dispatch(isLoader(false));
  });
};

export const verCursoTxt = (dato) => async (dispatch) => {
  const dbRef = db.ref("cursosInfo").child(dato);
  dispatch(isLoader(true));
  dbRef.on("value", (snap) => {
    const curso = snap.val();
    dispatch(verCursoTxtOk(curso));
    dispatch(isLoader(false));
  });
};

export const guardarVistos = (dato) => async (dispatch) => {
  var ref = db.ref("usuarios");
  dispatch(guardarProgreso(dato));
  try {
    var vistos = "vistos_" + dato.cursin;
    var usersRef = ref.child(dato.pibe).child(vistos);
    usersRef.push({
      url: dato.video,
    });
  } catch (err) {
    console.log(err);
  }
};

export const dameEmails = () => async (dispatch) => {
  console.log("entro a dameEmails");
  var ref = db.ref("usuarios");
  ref.on("child_added", (snapshot) => {
    const item = snapshot.val().email;
    let full = snapshot.val().standupfull;
    if (item != undefined && full) {
      console.log(item + ", ");
    }
  });
};
export const guardarProgreso = (dato) => async (dispatch) => {
  var ref = db.ref("usuarios");
  try {
    var usersRef = ref.child(dato.pibe).child("totales");
    usersRef.update({
      [dato.cursin]: dato.progreso,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addComentarioGeneral = (dato) => async (dispatch) => {
  var fecha = Date.now();
  var ref = db.ref("comentarios");
  var usersRef;
  if (dato.key !== "") {
    usersRef = ref.child(dato.cursin).child(dato.key).child("hilo");
  } else {
    usersRef = ref.child(dato.cursin);
  }
  //   var ref = db.ref("comentarios").child(this.video);
  //  try{
  //   var usersRef = ref.child(this.comentarioHijo).child("hilo");

  try {
    usersRef.push({
      time: fecha,
      nombre: dato.nombre,
      email: dato.email,
      uid: dato.uid,
      pic: dato.pic,
      mensaje: dato.mensaje,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getComentarios = (dato) => async (dispatch) => {
  var dbRefList = db.ref("comentarios").child(dato.cursin);
  dbRefList.on("child_added", (snap) => {
    var comentarios = snap.val();
    comentarios.keys = snap.key;
    dispatch(getComentariosOk(comentarios));
  });
};

export const dameMiCurso = (dato) => async (dispatch) => {
  var ref = db.ref("usuarios");
  try {
    var usersRef = ref.child(dato.pibe);
    for (const prop in dato.cursin) {
      usersRef.update({
        [dato.cursin[prop]]: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
///////// UTILS
const _hoyFecha = () => {
  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  var hoy = new Date();
  var dd = hoy.getDate();
  var mm = hoy.getMonth() + 1;
  var yyyy = hoy.getFullYear();
  dd = addZero(dd);
  mm = addZero(mm);
  return dd + "/" + mm + "/" + yyyy;
};

export const timeSince = (date) => {
  console.log("Manfamos a timeSince", date);

  var seconds = Math.floor((new Date() - date) / 1000);
  var interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + " años";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " meses";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " dias";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " horas";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutos";
  }
  return Math.floor(seconds) + " segundos";
};
