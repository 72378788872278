import React from "react";
import Back from "../assets/statics/bioBackground-compressor.jpg";
import Middle from "../assets/statics/30706451202_a42721862a_c.jpg";
const SobreMi = props => {
  const { _color_ } = props;
  return (
    <>
      <div className="biogrid">
        <div>
          <header className="sobremi">
            <img src={Back} alt="" className="yoni" />
            <a
              href="https://www.linkedin.com/in/jonatanariste/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fab fa-linkedin-in" style={{ color: _color_ }}></i>
            </a>
            <a
              href="https://www.instagram.com/escueladevrock/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fab fa-instagram" style={{ color: _color_ }}></i>
            </a>
            <a
              href="https://twitter.com/jonatanariste"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fab fa-twitter" style={{ color: _color_ }}></i>
            </a>
            <a
              href="https://github.com/jonatanariste"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fab fa-github" style={{ color: _color_ }}></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCWbXy_PK2I6fmJjD3NqSnRQ"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="fab fa-youtube" style={{ color: _color_ }}></i>
            </a>
          </header>

          <h2> Hola, soy Jonatan Ariste:</h2>
          <hr />
          <p>
            Soy autodidacta, trabajo hace mas de 10 años como freelancer y
            colaboré en proyectos para todo el mundo. Trabajé para Madonna,
            Quilmes, Victoria Secret's, Dove, Electonics Arts entre otras marcas
            de varios paises. En esas empresas desarrollé diferentes tareas, fui
            frontend, UX, PM y todo lo que se necesitó para el desarrollo
            integral de esos proyectos.
          </p>
          <p>
            Fundé el Google Developer Groups Río de la Plata el cual sigue al
            día de hoy brindando eventos y charlas sobre tecnologías
            Google. Siendo parte de GDG tuve la oportunidad de dar muchísimas
            conferencias por todo LATAM sobre diferentes tecnologías. Siendo
            speaker tuve el privilegio de dar una conferencia TEDx.
          </p>
          <p>
            Hoy en Escuela Dev Rock enseño todo lo que sé a través de mis
            cursos online con los que ya se
            formaron más de 2 mil personas, muchas de las
            cuales ya están trabajando de lo que aprendieron.
          </p>
          <p>
            También fui columnista de tecnología en FM Rock & Pop, speaker en
            Campusparty, colaborador en Barcamp y otros eventos más.
          </p>
          <p>
            Actualmente me encuentro viajando por Asia, hace algunos años que
            viajo durante 6 meses y el resto del año lo paso en mi casa en
            Berazategui.
          </p>
          <p>
            Te dejo algunos accesos a conferencias
            <strong>
              <a
                href="https://www.youtube.com/playlist?list=PLxqDsbl5QJG2k_W94xC-ywuc0A_12MZ9m"
                rel="noopener noreferrer"
                target="_blank"
              >
                acá en Youtube.
              </a>
            </strong>
          </p>
          <p>
            Gracias por confiar en Escuela Dev Rock, espero te sumes a esta gran
            comunidad.
          </p>
        </div>
      </div>
      <img src={Middle} alt="" className="fotocentral" />
    </>
  );
};

export default SobreMi;
