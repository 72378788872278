import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "../components/Layout";
import Home from "../containers/Home";
import Login from "../containers/Login";
import Comprar from "../containers/Comprar";
import NotFound from "../containers/NotFound";
import Curso from "../containers/Curso";
import MisCursos from "../containers/MisCursos";
import "../assets/styles/App.css";
import { connect } from "react-redux";
import { getInfo, isMobileOUKE } from "../actions/";
import Ver from "../containers/Ver";

const App = props => {
  const { getInfo, isMobileOUKE } = props;
  useEffect(() => {
    const constructora = () => {
      window.addEventListener("resize", handleWindowSizeChange);
      getInfo();
    };
    const handleWindowSizeChange = () => {
      isMobileOUKE();
    };
    constructora();
  }, [getInfo, isMobileOUKE]);

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/404" component={NotFound}></Route>
          <Route exacr path="/comprar" component={Comprar}></Route>
          <Route exacr path="/login" component={Login}></Route>
          <Route exacr path="/miscursos" component={MisCursos}></Route>
          <Route exacr path="/ver" component={Ver}></Route>

          <Route component={Curso}></Route>
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

const mapDispatchToProps = {
  getInfo,
  isMobileOUKE
};

export default connect(
  null,
  mapDispatchToProps
)(App);
