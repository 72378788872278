import React, { useEffect } from "react";
import { connect } from "react-redux";
import { PayPalButton } from "react-paypal-button-v2";
import MercadoPago from "../components/MercadoPago";
import CarritoItems from "../components/CarritoItems";
import PP from "../assets/statics/comprar_paypal.png";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../assets/styles/Comprar.css";
import {
  autoCarga,
  dameMiCurso,
  deleteItemCarrito,
  sendToast,
} from "../actions";

const Comprar = (props) => {
  const { autoCarga, history, dameMiCurso } = props;
  const masDeUnoDescuento = 20;
  const { uid, email, username } = props.user;
  const { carrito } = props;
  const { nombre, longDescription, img_prod } = "";
  useEffect(() => {
    let decode = atob(history.location.hash.substr(1));
    if (decode !== "") {
      autoCarga(decode);
    }
  }, [autoCarga, history]);

  // eslint-disable-next-line
  let id = 0;
  let isOferta = false;
  // eslint-disable-next-line
  let totalDolar = 0;
  let totalPesos = 0;
  let totalAntesDescuento = 0;
  let total = 0;
  let ahorro = 0;
  const valorDolar = 50;
  for (const prop in carrito) {
    id++;
    if (carrito[prop].oferta === "no") {
      total = total + parseInt(carrito[prop].precio);
    } else {
      isOferta = true;
      let desc = 100 - parseInt(carrito[prop].descuento);
      let totalx = parseInt(carrito[prop].precio);
      totalAntesDescuento =
        totalAntesDescuento + parseInt(carrito[prop].precio);
      totalx = (totalx * desc) / 100;
      total = total + totalx;
      ahorro =
        ahorro +
        (parseInt(carrito[prop].precio) * parseInt(carrito[prop].descuento)) /
          100;
    }
  }
  const dameMiCursoGRatis = () => {
    let cursos = [];
    for (const prop in carrito) {
      let n = carrito[prop].url;
      n = n.substr(1);
      props.deleteItemCarrito(carrito[prop].nombre);
      cursos.push(n);
      setTimeout(eliminar, 100);
      function eliminar() {
        props.sendToast([
          `Ya tienes :"${carrito[prop].nombre}" en tus cursos.`,
          "success",
        ]);
      }
      // props.history.push("/miscursos");
    }

    props.dameMiCurso({ pibe: uid, cursin: cursos });
  };

  return (
    <>
      <Helmet>
        <title>Curso de stand up online | Señales de humor</title>
        <meta
          name="description"
          content="Aprende las herramientas para escribir e interpretar monólogos de humor stand up. Sumate a nuestro curso gratuito."
        />
      </Helmet>
      <div className="login">
        <div className="login__wrapper white__wrapper">
          <h2 className="itemCarrito--h2">Estás a punto de comprar: </h2>
          {carrito.map((item, i) => (
            <CarritoItems key={i} {...item} history={history} />
          ))}
          <div className="comprar__wrapper">
            {Object.keys(carrito).length <= 1 || isOferta === true ? (
              <>
                {!isOferta ? (
                  <Link to="/"></Link>
                ) : (
                  <>
                    <p className="Compras--totalAntesDescuento">
                      <del>Total: AR${totalAntesDescuento}</del>
                    </p>
                    <p className="Compras--ahorro">Ahorras: AR${ahorro}</p>
                  </>
                )}

                <p className="Compras--total">
                  Total AR$ <strong>{(totalPesos = total)}</strong>
                </p>
              </>
            ) : (
              <>
                <p>20% OFF por comprar más de un curso.</p>
                <p className="Compras--totalAntesDescuento">
                  <del>
                    Total AR$ <strong>{(totalPesos = total)}</strong>
                  </del>
                </p>
                {
                  <p className="invisible">
                    {(ahorro = (total * masDeUnoDescuento) / 100)}
                  </p>
                }
                <p>Ahorrás: AR${ahorro}</p>
                <p className="Compras--total">
                  Total AR$ <strong>{(totalPesos = (total * 80) / 100)}</strong>
                </p>
              </>
            )}
          </div>
          <div className="comprar-wrapper-pagos">
            {uid && totalPesos > 0 ? (
              <>
                <p className="Compras--dolaresTotal">{`Pagos desde Argentina: AR$${totalPesos}`}</p>
                <MercadoPago
                  precio={totalPesos}
                  nombre={nombre}
                  username={username}
                  img_prod={img_prod}
                  uid={uid}
                  email={email}
                  carrito={carrito}
                ></MercadoPago>
                <p className="Compras--dolaresTotal otrop">
                  En dolares por Paypal.
                </p>
                <p className="Compras--dolaresTotal">U$D67</p>

                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_xclick" />
                  <input
                    type="hidden"
                    name="business"
                    value="pagos@senalesdehumor.com.ar"
                  />
                  <input type="hidden" name="lc" value="MX" />
                  <input
                    type="hidden"
                    name="item_name"
                    value="Curso online de stand up"
                  />
                  <input type="hidden" name="item_number" value="C03" />
                  <input type="hidden" name="amount" value="67.00" />
                  <input type="hidden" name="currency_code" value="USD" />
                  <input type="hidden" name="button_subtype" value="services" />
                  <input type="hidden" name="no_note" value="0" />
                  <input
                    type="hidden"
                    name="bn"
                    value="PP-BuyNowBF:btn_buynowCC_LG.gif:NonHostedGuest"
                  />

                  <input
                    className="pagoPaypal"
                    type="image"
                    src={PP}
                    border="0"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
                <p className="discl">
                  Tené en cuenta que la aprobacion del pago y el acceso al curso
                  pueden demorar dependiendo el metodo de pago que utilices
                  (MercadoPago o Paypal) <br /> Sé paciente.
                </p>
                {/* <br />
                <p className="Compras--dolaresTotal">{`Resto del mundo: U$D${(totalDolar =
                  totalPesos / valorDolar)}`}</p> */}
                {/* <PayPalButton
                  onCancel={data => {
                    console.log("cancelaron buacho", data);
                  }}
                  onError={data => {
                    console.log("Error de algo", data);
                  }}
                  disableCard="none"
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            currency_code: "USD",
                            value: totalDolar
                          }
                        }
                      ]
                    });
                  }}
                  onSuccess={(details, data) => {
                    dameMiCursoGRatis();

                    return console.log("todo piola desde Paypal");
                  }}
                  options={{
                    clientId:
                      "AexOAkfycHXJUovhAy-2xoM7M64QwDiID8DBtDBPtHmTcyHLbcIVPx6JRtOejLyicrI-qymkmj2k1u4y"
                  }}
                /> */}
              </>
            ) : !uid ? (
              <>
                <br />
                <br />
                <br />
                <p>Para finalizar tu compra tenés que loguearte :)</p>
                <Link to="/login">
                  <button className="btn-success">Loguear</button>
                </Link>
              </>
            ) : (
              Object.keys(carrito).length > 0 && (
                <button className="btn-success" onClick={dameMiCursoGRatis}>
                  Dame mi curso gratis
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    carrito: state.carrito,
  };
};

const mapDispatchToProps = {
  autoCarga,
  dameMiCurso,
  deleteItemCarrito,
  sendToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Comprar);
