import React from "react";
import { connect } from "react-redux";
import { logout, sendToast } from "../actions";
import { Link } from "react-router-dom";
import Anonimo from "../assets/statics/anonimo.jpg";
import "../assets/styles/Header.css";
import Logo from "../assets/statics/logo.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// "https://thispersondoesnotexist.com/image"

const Header = props => {
  toast.configure({
    autoClose: 4000,
    draggable: false
  });
  const { user, carrito, numeroItems, pack, toastMessage } = props;
  const { profile_picture, uid } = user;
  const handleClose = e => {
    e.preventDefault();
    props.logout();
  };

  const notify = () => {
    const menssage = toastMessage[0];
    const tipo = toastMessage[1];

    props.sendToast([]);
    switch (tipo) {
      case "info":
        toast.info(menssage, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        break;
      case "error":
        toast.error(menssage, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        break;
      case "warn":
        toast.warn(menssage, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        break;
      case "success":
        toast.success(menssage, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        break;
      default:
        toast(menssage);
    }
  };
  return (
    <>
      {Object.keys(toastMessage).length > 0 ? notify() : null}
      <header className="header">
        <Link to="/">
          <img className="header__img" src={Logo} alt="Escuela Dev Rock" />{" "}
        </Link>
        {Object.keys(carrito).length > 0 && (
          <Link to="/comprar">
            <div className="labelCarrito">
              <p className="numeroItemsCarrito">Comprar </p>
              <i className="fas fa-shopping-cart carritoTotal"></i>
              <p className="numeroItemsCarrito">
                {!pack ? carrito.length : numeroItems} curso
                {numeroItems > 1 && "s"}
              </p>
            </div>
          </Link>
        )}

        <div className="header__menu">
          <div className="header__menu--profile">
            {uid && profile_picture ? (
              <>
                {/* <p className="off"> {displayName}</p> */}
                <img src={profile_picture} alt="" />
              </>
            ) : (
              <>
                <Link to="/login">
                  <spam className="ingres">Ingresar</spam>
                  <img src={Anonimo} alt="" className="anonimo" />
                </Link>
              </>
            )}
          </div>

          {uid && (
            <ul>
              <li>
                <Link to="/miscursos">Mis Cursos</Link>
              </li>
              <li>
                <a href="/" onClick={handleClose}>
                  Cerrar Sesión
                </a>
              </li>
            </ul>
          )}
        </div>
      </header>
      <ToastContainer />
    </>
  );
};
const mapStateToProps = state => {
  return {
    user: state.user,
    carrito: state.carrito,
    numeroItems: state.numeroItems,
    pack: state.pack,
    toastMessage: state.toast
  };
};

const mapDispatchToProps = {
  logout,
  sendToast
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
