import React from "react";
import Loader from "./Loader";
import { connect } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
const Layout = props => {
  const { children, isLoader } = props;
  return (
    <>
      {isLoader && <Loader></Loader>}
      <Header></Header>
      {children}
      <Footer></Footer>
    </>
  );
};

const mapStateToProps = state => {
  return {
    isLoader: state.isLoader
  };
};

export default connect(
  mapStateToProps,
  null
)(Layout);
