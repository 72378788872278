import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import "../assets/styles/Footer.css";

const Footer = () => {
  const url =
    "https://escueladevrock.us12.list-manage.com/subscribe/post?u=330a9dc7fae796bcf8657f1f9&amp;id=ad8b34db4c";

  return <></>;
};
export default Footer;
