import React from "react";
import { connect } from "react-redux";
import { setUser } from "../actions/";
import "../assets/styles/Login.css";
import { Link } from "react-router-dom";

const Login = props => {
  const { user } = props;
  const handleLogin = e => {
    e.preventDefault();
    props.setUser(e.target.id);
  };
  return (
    <>
      <div className="login">
        <div className="login__wrapper">
          <h2>Bienvenidx!</h2>
          {Object.keys(user).length <= 0 ? (
            <>
              <p>
                Ingresá con tu cuenta de Facebook o Gmail. Elegí una y siempre
                ingresa con la misma red social.
              </p>
              <div className="redes">
                <i
                  className="fab fa-facebook-square"
                  onClick={handleLogin}
                  id="facebook"
                ></i>
                <i
                  className="fab fa-google"
                  id="google"
                  onClick={handleLogin}
                ></i>

                {/* <i
                  className="fab fa-twitter"
                  onClick={handleLogin}
                  id="twitter"
                ></i> */}
              </div>
            </>
          ) : (
            <>
              <p>Ya estás logeadx :)</p>{" "}
              <Link to="./miscursos">
                Ir a "Mis Cursos" y empezar a estudiar!
              </Link>{" "}
            </>
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = {
  setUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
