import React from "react";
import CursoTemas from "./CursoTemas";
import { Accordion, AccordionItem } from "react-sanfona";
const CursoPrograma = props => {
  const { programa, _color_ } = props;
  const createPrograma = () => {
    let table = [];
    let id = "";
    for (const prop in programa) {
      id++;
      table.push(
        <AccordionItem title={`▾ ${programa[prop].nombre}`} key={id}>
          <CursoTemas
            temas={programa[prop].temas}
            _color_={_color_}
          ></CursoTemas>
        </AccordionItem>
      );
    }
    return table;
  };

  return (
    <>
      <Accordion>{createPrograma()}</Accordion>
    </>
  );
};
export default CursoPrograma;
