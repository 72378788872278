import React from "react";
const MensajeHijo = props => {
  const { msn, keys } = props;
  let ver = false;
  const toggleVer = e => {
    if (!ver) {
      ver = true;
      e.target.innerHTML = "---- Ocultar respuestas";
      document.getElementById(keys).style.display = "block";
    } else {
      ver = false;
      e.target.innerHTML = `---- Ver Respuestas (${Object.keys(msn).length})`;
      document.getElementById(keys).style.display = "none";
    }
  };
  const crearTemas = () => {
    let table = [];
    // eslint-disable-next-line
    let id = 0;
    for (const prop in msn) {
      id++;
      table.push(
        <li key={msn[prop].key} className="liWrapper">
          <img src={msn[prop].pic} alt="" className="picChatHijo" />
          <div className="containerTXTChat">
            <p className="mensajetxtHijo">
              <span className="nic">{msn[prop].nombre}</span>{" "}
              {msn[prop].mensaje}
            </p>
            <p className="mensajeDatos"> {tirameLasAgujas(msn[prop].time)}</p>
          </div>
        </li>
      );
    }
    return table;
  };
  const tirameLasAgujas = date => {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
      return interval + "años";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + "meses";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + "dias";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + "hs";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + "min";
    }
    return Math.floor(seconds) + " seg";
  };
  return (
    <>
      <div className="mensajeHijo">
        {msn !== undefined && (
          <span className="verRespuestas" onClick={toggleVer}>
            ---- Ver Respuestas ({Object.keys(msn).length})
          </span>
        )}

        <ul className="ulHijo" id={keys}>
          {crearTemas()}
        </ul>
      </div>
    </>
  );
};
export default MensajeHijo;
