import React from "react";
import { connect } from "react-redux";
import { bajarCertificado } from "../actions";
import "../assets/styles/CursoComprado.css";
const CursoComprado = props => {
  const {
    uid,
    nombre,
    img_prod,

    _color_,

    url,
    history,

    totales,
    userNombre,
    userMail
  } = props;
  // eslint-disable-next-line
  let qr;

  const bajarCertificado = () => {
    let urlx =
      "https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Fescueladevrock.com%2Fcertificados%2F" +
      uid +
      "&chs=180x180&choe=UTF-8&chld=L|2%27%20rel=%27nofollow";
    var canvas = document.getElementById("micanvas");
    var ctx = canvas.getContext("2d");
    var img = new Image();
    img.src = urlx;
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      let dataURL = canvas.toDataURL();
      qr = dataURL;

      props.bajarCertificado({
        curso: url.substr(1),
        uidx: uid,
        userNombre: userNombre,
        mail: userMail,
        qr: dataURL
      });
    };
  };

  const handleVer = () => {
    history.push(`/ver${url}`);
  };

  return (
    <>
      <canvas height="180px" width="180px" id="micanvas"></canvas>

      <div
        className="cursoComprado__wrapper"
        style={{ backgroundColor: _color_ }}
      >
        <div className="cursoComprado__foto">
          <img
            src={`https://escueladevrock.com/statics/${img_prod}`}
            alt=""
            className="cursoComprado__pic"
          />
        </div>
        <div className="cursoComprado__info">
          <h3 className="cursoComprado__info--titulo">{nombre}</h3>
          <p className="cursoComprado__info--restan">Restan 365 dias</p>
          {totales &&
            (totales[url.substr(1)] === 100 && (
              <button
                className="cursoComprado--btn off"
                onClick={bajarCertificado}
              >
                Certificado <i className="fas fa-file-download"></i>
              </button>
            ))}

          <button className="cursoComprado--btn" onClick={handleVer}>
            Ver curso <i className="fas fa-play-circle"></i>
          </button>
          {totales && totales[url.substr(1)] ? (
            <progress
              value={totales[url.substr(1)]}
              max="100"
              id="progreso"
            ></progress>
          ) : (
            <p className="cursoComprado_inicialo">
              Arranca hoy a ver este curso!
            </p>
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = state => {
  return {
    totales: state.totales
  };
};

const mapDispatchToProps = {
  bajarCertificado
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CursoComprado);
