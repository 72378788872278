import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../assets/styles/CarouselItem.css";
import { addCarrito, sendToast } from "../actions";

const CarouselItem = props => {
  const {
    _color_,
    //cantidad,
    descripcion,
    logo,
    descuento,
    // logoutRequest,
    toast,
    gratis,
    url,
    nombre,
    oferta,
    ofertaInfo,
    precio,
    precioDolar
  } = props;
  const handleCarrito = e => {
    props.addCarrito(url.substr(1));
    if (toast) {
      props.sendToast([toast, "info"]);
    } else {
      props.sendToast([`Agregaste ${nombre} al carrito.`, "info"]);
    }
  };

  var precioFinal = precio;
  if (oferta !== "no") {
    let des = 100 - descuento;
    precioFinal = (precio * des) / 100;
  }

  return (
    <>
      <div className="col-md-6">
        <div className="carousel-item" style={{ backgroundColor: _color_ }}>
          <img
            src={`https://escueladevrock.com/${logo}`}
            alt=""
            className="carousel-item__img"
          />
          <div className="carousel-item__details">
            <p className="carousel-item__details--title">{nombre}</p>
            <p className="carousel-item__details--subtitle">{descripcion} {!gratis && (<><br/><br/> <strong> Precio en U$D {precioDolar}</strong></>)}   </p>

            {oferta !== "no" && (
              <>
                <p className="carousel-item__details--oferta">
                  <del>AR$ {precio}</del> {ofertaInfo}
                </p>
              </>
            )}

            <div className="actions">
              {gratis ? (
                <>
                  <Link to="/login">
                    <button type="button">SUMATE GRATIS</button>
                  </Link>
                </>
              ) : (
                <>
                  <button type="button" onClick={handleCarrito}>
                    COMPRAR AR$ {precioFinal}
                  </button> 
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  addCarrito,
  sendToast
};

export default connect(null, mapDispatchToProps)(CarouselItem);
